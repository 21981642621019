import "./style.scss";

import _, { debounce } from "lodash";

// import $ from "jquery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, SplitText);

import Lenis from "@studio-freight/lenis";

const menu_toggle = document.getElementById("menu-toggle");
menu_toggle.addEventListener("click", () => {
  if (menu_toggle.classList.contains("active")) {
    menu_toggle.classList.remove("active");
    // menu_toggle.querySelector("span").textContent = "Menu";

    gsap
      .timeline()
      .to("#menu-main li", { autoAlpha: 0 }, 0)
      .to("#background-transparancy", { autoAlpha: 0 }, 0.5)
      .to(
        ".menu-bg",
        {
          y: () => {
            return document.documentElement.clientWidth < 1024 ? "-100%" : 0;
          },
          x: () => {
            return document.documentElement.clientWidth < 1024 ? 0 : "100%";
          },
        },
        0.5,
      )
      .set("#menu", { autoAlpha: 0 });
  } else {
    menu_toggle.classList.add("active");
    // menu_toggle.querySelector("span").textContent = "Close";

    gsap
      .timeline()
      .set("#menu", { autoAlpha: 1 })
      .to("#background-transparancy", { autoAlpha: 0.6 }, 0)
      .to(
        ".menu-bg",
        {
          y: () => {
            return document.documentElement.clientWidth < 1024 ? 0 : 0;
          },
          x: () => {
            return document.documentElement.clientWidth < 1024 ? 0 : 0;
          },
        },
        0,
      )
      .fromTo(
        "#menu-main li",
        { autoAlpha: 0 },
        { autoAlpha: 1, stagger: 0.1 },
      );
  }
});

const lenis = new Lenis();

lenis.on("scroll", (e) => {
  // console.log(e);
});

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

const heros = document.querySelectorAll(".hero");
let hasSplitText = false;

let mm = gsap.matchMedia();

// add a media query. When it matches, the associated function will run
mm.add("(min-width: 1024px)", () => {
  let sections = gsap.utils.toArray(".scroll .panel");
  let hero = gsap.utils.toArray(".scroll .hero");
  let headerWidth = document.querySelector("header").clientWidth;
  let container = document.querySelector(".scroll-container");
  let sectionsHalf = gsap.utils.toArray(".panel-half");
  let sectionsLength = sections.length - sectionsHalf.length / 2;

  let split1;
  let split2;

  pageWidth();

  function pageWidth() {
    gsap.set(sections, {
      width: document.documentElement.clientWidth - headerWidth,
    });

    // gsap.set(hero, {
    //   width: document.documentElement.clientWidth,
    // });

    gsap.set(sectionsHalf, {
      width: document.documentElement.clientWidth / 2 - headerWidth / 2,
    });

    gsap.set(container, {
      width:
        document.documentElement.clientWidth * sectionsLength -
        sectionsLength * headerWidth,
    });

    setTimeout(function () {
      if (!hasSplitText) {
        // split to line to hide overflow
        split1 = new SplitText(".prose h3, .prose h2, .prose p, .prose li", {
          type: "lines",
          linesClass: "stagger-copy-line overflow-hidden",
        });

        // split again to hide
        split2 = new SplitText(".stagger-copy-line", {
          type: "lines",
          linesClass: "stagger-copy-line-inner",
        });
        // stops split happening again on resize
        hasSplitText = true;
      }
    }, 500);
  }

  let pageScroll = gsap.to(sections, {
    xPercent: function (index, target, targets) {
      let percent = target.classList.contains("panel-half") ? -200 : -100;
      return percent * (sectionsLength - 1);
    },
    ease: "none",
    scrollTrigger: {
      trigger: ".scroll",
      pin: true,
      scrub: true,
      // markers: true,
      end: `+=${container.offsetWidth}`,
      fastScrollEnd: true,
      preventOverlaps: true,
      anticipatePin: 1,
      invalidateOnRefresh: true,
      onRefresh: _.debounce(() => {
        pageWidth();
      }, 300),
    },
  });

  // zoom in effect
  let images = gsap.utils.toArray(".image-wrapper");
  images.forEach((image) => {
    gsap.to(image.querySelector("img"), {
      scale: 1,
      ease: "power1.out",
      duration: 1,
      scrollTrigger: {
        trigger: image,
        containerAnimation: pageScroll,
        start: "left 80%",
        toggleActions: "play none none reverse",
        // markers: true,
      },
    });
  });

  // parallax horizontally
  let parallaxImages = gsap.utils.toArray(".image-parallax");
  parallaxImages.forEach((image) => {
    gsap.fromTo(
      image.querySelector("img"),
      { xPercent: -25 },
      {
        xPercent: 25,
        ease: "none",
        duration: 1,
        scrollTrigger: {
          trigger: image,
          containerAnimation: pageScroll,
          start: "left right",
          scrub: true,
          toggleActions: "play none none reverse",
          // markers: true,
        },
      },
    );
  });

  // section 4 hover

  const titles = document.querySelectorAll("#project-titles > li");

  titles.forEach((el) => {
    el.addEventListener("mouseenter", () => {
      // set active class

      gsap
        .timeline()
        .set(titles, { fontWeight: 400 })
        .set(el, { fontWeight: 700 });

      let projectID = el.dataset.id;
      let project = document.querySelectorAll(
        '#project-cards > div[data-id="' + projectID + '"]',
      );

      // console.log(project[0]);

      gsap
        .timeline()
        .set("#project-cards > div", { autoAlpha: 0 })
        .set(project, { autoAlpha: 1 })
        .from(project[0].querySelector("img"), { scale: 1.25 }, { scale: 1 });
    });
  });

  let prose = gsap.utils.toArray(".prose");
  prose.forEach((line) => {
    gsap.fromTo(
      line.querySelectorAll(".stagger-copy-line-inner"),
      {
        yPercent: 100,
      },
      {
        yPercent: 0,
        stagger: 0.1,
        scrollTrigger: {
          trigger: line,
          //   markers: true,
          containerAnimation: pageScroll,
          start: "left 80%",
        },
        onComplete: () => line.classList.add("complete"),
      },
    );
  });

  //

  return () => {
    // custom cleanup code here (runs when it STOPS matching)

    // unhide by removing inline styles
    let projectCards = document.querySelectorAll("#project-cards > div");
    projectCards.forEach((card) => card.removeAttribute("style"));

    // remove inline styles
    document.querySelector(".menu-bg").removeAttribute("style");

    // destroy split so can reinit
    split1.revert();
    split2.revert();
    hasSplitText = false;
  };
});
// mm.revert();

mm.add("(max-width: 1023px)", () => {
  // blank vars allow cleanup
  let split1;
  let split2;

  if (!hasSplitText) {
    // split to line to hide overflow
    split1 = new SplitText(".prose h3, .prose h2, .prose p, .prose li", {
      type: "lines",
      linesClass: "stagger-copy-line overflow-hidden",
    });

    // split again to hide
    split2 = new SplitText(".stagger-copy-line", {
      type: "lines",
      linesClass: "stagger-copy-line-inner",
    });
    // stops split happening again on resize
    hasSplitText = true;
  }

  // zoom in effect
  let zoomImages = gsap.utils.toArray(".image-wrapper");
  zoomImages.forEach((image) => {
    gsap.to(image.querySelector("img"), {
      scale: 1,
      ease: "power1.out",
      duration: 1,
      scrollTrigger: {
        trigger: image,
        start: "top 80%",
        toggleActions: "play none none reverse",
        // markers: true,
      },
    });
  });

  // parallax vertically
  let parallaxImages = gsap.utils.toArray(".image-parallax");
  parallaxImages.forEach((image) => {
    gsap.fromTo(
      image.querySelector("img"),
      { yPercent: -15 },
      {
        yPercent: 25,
        ease: "none",
        duration: 1,
        scrollTrigger: {
          trigger: image,
          start: "top bottom",
          scrub: true,
          toggleActions: "play none none reverse",
          // markers: true,
        },
      },
    );
  });

  // make hero full height minus header

  heros.forEach((element) => {
    let windowHeight = window.innerHeight;
    let headerHeight = document.querySelector("header").clientHeight;
    element.classList.remove("min-h-screen");
    gsap.set(element, {
      minHeight: windowHeight - headerHeight,
    });
  });

  // text effect

  let prose = gsap.utils.toArray(".prose");
  prose.forEach((line) => {
    gsap.fromTo(
      line.querySelectorAll(".stagger-copy-line-inner"),
      {
        yPercent: 100,
      },
      {
        yPercent: 0,
        stagger: 0.1,
        scrollTrigger: {
          trigger: line,
          //   markers: true,
          start: "top 90%",
        },
      },
    );
  });

  return () => {
    // custom cleanup code here (runs when it STOPS matching)

    // give full height back
    heros.forEach((hero) => hero.classList.add("min-h-screen"));

    document.querySelector(".menu-bg").removeAttribute("style");

    // destroy split so can reinit on deivce change
    split1.revert();
    split2.revert();
    hasSplitText = false;
  };
});

gsap
  .timeline({
    defaults: {
      duration: 0.5,
    },
  })
  .to("#loader-heading svg", { y: 0 })
  .to("#loader-icon line", { opacity: 1, stagger: 0.5 })
  .to("#loader-tagline", { opacity: 1 })
  .addLabel("wipe1")
  .to("#loader-grey", { x: 0 }, "wipe1")
  .to("#loader-heading svg", { width: "auto" }, "wipe1")
  .set("#loader-icon", { opacity: 0 })
  .addLabel("wipe2")
  .to("#loader-grey", { x: "-100%" }, "wipe2")
  .to("#loader-black", { x: "-100%" }, "wipe2")
  .to("#hero-opacity", { opacity: 0.8 })
  .set("#loader", { autoAlpha: 0 });
//   .addLabel("header")
//   .to(
//     hero,
//     {
//       width: document.documentElement.clientWidth - headerWidth,
//     },
//     "header",
//   )
//   .to(
//     hero,
//     {
//       width: document.documentElement.clientWidth - headerWidth,
//     },
//     "header",
//   );
